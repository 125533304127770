import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  resetSurveyValues,
  selectRetentionOffers,
  selectSurveyValues,
} from '@mfe/to-be-migrated/redux/disconnect';
import { selectChangePlan } from '@mfe/to-be-migrated/redux/changePlan';
import { scrollToTop } from '@mfe/shared/redux/utils';

import { useHasOffersToDisplay } from './shared/hooks';
import { DisconnectSteps } from './shared/types';
import { DisconnectStep1 } from './step-1';
import { DisconnectStep2 } from './step-2';
import { DisconnectStep3 } from './step-3';
import { useTranslation } from 'react-i18next';
import { selectHasPreinstallProductInstanceStatus } from '@mfe/to-be-migrated/redux/auth';

type Props = {
  handleIntroContinue: (step: DisconnectSteps) => void;
  handleReviewCancel: (step: DisconnectSteps) => void;
  formError?: string;
  step: DisconnectSteps;
  setStep: Dispatch<SetStateAction<DisconnectSteps>>;
};

export const DisconnectPage = () => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(DisconnectSteps.INTRO);

  const { data: retentionOffers } = useSelector(selectRetentionOffers);
  const { planOffersData } = useSelector(selectChangePlan);
  const shouldRenderRetentionStep = useHasOffersToDisplay(
    retentionOffers,
    planOffersData
  );

  const hasPreinstall = useSelector(selectHasPreinstallProductInstanceStatus);

  const { handleIntroContinue, handleReviewCancel, formError } =
    useNavigationHandlers(setStep);

  useEffect(() => {
    return () => {
      dispatch(resetSurveyValues());
    };
  }, [dispatch]);

  const props: Props = {
    handleIntroContinue,
    handleReviewCancel,
    formError,
    step,
    setStep,
  };

  return shouldRenderRetentionStep && !hasPreinstall
    ? renderThreeStepProcess(props)
    : renderTwoStepProcess(props);
};

function renderThreeStepProcess(props: Props) {
  const { handleIntroContinue, handleReviewCancel, step, setStep } = props;

  const handleContinue = () => handleIntroContinue(DisconnectSteps.RETENTION);
  const handleCancel = () => handleReviewCancel(DisconnectSteps.RETENTION);

  switch (step) {
    case DisconnectSteps.INTRO:
      return <DisconnectStep1 handleContinue={handleContinue} />;
    case DisconnectSteps.RETENTION:
      return <DisconnectStep2 setStep={setStep} />;
    case DisconnectSteps.REVIEW:
      return <DisconnectStep3 step={3} handleCancel={handleCancel} />;
    default:
      return null;
  }
}

function renderTwoStepProcess(props: Props) {
  const { handleIntroContinue, handleReviewCancel, step, formError } = props;

  const handleContinue = () => handleIntroContinue(DisconnectSteps.REVIEW);
  const handleCancel = () => handleReviewCancel(DisconnectSteps.INTRO);

  switch (step) {
    case DisconnectSteps.INTRO:
      return (
        <DisconnectStep1
          handleContinue={handleContinue}
          formError={formError}
        />
      );
    case DisconnectSteps.REVIEW:
      return <DisconnectStep3 step={2} handleCancel={handleCancel} />;
    default:
      return null;
  }
}

const useNavigationHandlers = (
  setStep: Dispatch<SetStateAction<DisconnectSteps>>
) => {
  const dispatch = useDispatch();
  const { disconnectReason } = useSelector(selectSurveyValues);
  const { t } = useTranslation();
  const hasPreinstall = useSelector(selectHasPreinstallProductInstanceStatus);

  const [formError, setFormError] = useState<string | undefined>(undefined);

  const handleIntroContinue = (step: DisconnectSteps) => {
    const error = document.querySelector('.beam-text-field__errors');
    if (error) {
      error.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return;
    }

    if (hasPreinstall) {
      if (!disconnectReason) {
        setFormError(t('Disconnect:survey.error.text'));
      } else {
        setFormError(undefined);
        setStep(step);
      }
    } else {
      setStep(step);
    }
    dispatch(scrollToTop());
  };

  const handleReviewCancel = (step: DisconnectSteps) => {
    setStep(step);
    dispatch(scrollToTop());
  };

  return { handleIntroContinue, handleReviewCancel, formError };
};
