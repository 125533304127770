import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getDisplayPlatform,
  inViewStructuredEvent,
  selectAnalytics,
} from '@mfe/to-be-migrated/redux/analytics';
import {
  selectHasPreinstallProductInstanceStatus,
  selectUser,
} from '@mfe/to-be-migrated/redux/auth';
import { Alert, selectAlerts } from '@mfe/to-be-migrated/redux/alerts';
import { Storage, SESSION_STORAGE_VARIABLES } from '@mfe/shared/util';
import {
  useModalContext,
  PageAlert,
  wasAlertDismissed,
} from '@mfe/shared/components';
import { useDisplayDisconnectAlert } from '@mfe/features/click-to-disconnect';
import { selectDisconnectInternet } from '@mfe/to-be-migrated/redux/disconnect';

import styles from '../../../Overview/Overview.module.scss';
import { selectConfig } from '@mfe/shared/redux/config';

interface AlertsSectionProps {
  navigateToPage: (link: string) => void;
}

export const AlertsSection = ({
  navigateToPage,
}: AlertsSectionProps): JSX.Element | null => {
  const dispatch = useDispatch();

  const { success: hasDisconnected } = useSelector(selectDisconnectInternet);
  const { showClickToDisconnect } = useSelector(selectConfig);
  const hasPreinstall = useSelector(selectHasPreinstallProductInstanceStatus);

  const displayDisconnectAlert = useDisplayDisconnectAlert();

  React.useEffect(() => {
    if (hasDisconnected && showClickToDisconnect && !hasPreinstall) {
      displayDisconnectAlert();
    }
  }, [hasDisconnected]);

  const { alerts, loading } = useSelector(selectAlerts);
  const { usageContext } = useSelector(selectAnalytics);
  const {
    user: {
      auth: {
        tokenInfo: { type: tokenType },
      },
    },
  } = useSelector(selectUser);

  const { dialogTypeHandlers } = useModalContext();
  const { setBuyMoreDialog } = dialogTypeHandlers;

  const [displayAlerts, setDisplayAlerts] = React.useState<Alert[]>([]);

  React.useEffect(() => {
    const storageData = Storage.getItem(
      SESSION_STORAGE_VARIABLES.DISMISSED_ALERTS
    );
    const alertsToDisplay = alerts.filter((alert) => {
      const wasDismissed = Object.keys(storageData || {}).includes(
        alert.title as string
      );

      return !wasDismissed;
    });

    setDisplayAlerts(alertsToDisplay);
  }, [alerts]);

  const displayContext = React.useMemo(
    () => ({
      schema: 'display_context',
      data: {
        display_zone: 'overview_page', //This is because billing is using a different one
        display_platform: getDisplayPlatform(tokenType),
        display_location: 'header',
      },
    }),
    [tokenType]
  );

  React.useEffect(() => {
    if (!loading && displayAlerts.length) {
      displayAlerts.forEach((alert) => {
        dispatch(
          inViewStructuredEvent({
            selector: '.alerts-section',
            category: 'Alert',
            action: 'displayed',
            triggerOnce: false,
            params: {
              property: alert.title,
              context: [displayContext, usageContext],
            },
          })
        );
      });
    }
  }, [dispatch, loading, alerts, displayContext, usageContext]);

  if (loading) return null;

  return (
    <div className={styles['alertsContainer']}>
      {displayAlerts
        .filter((alert) => !wasAlertDismissed(alert))
        .map((alert, i) => (
          <PageAlert
            key={i}
            alert={alert}
            loading={loading}
            analyticsContexts={{ displayContext, usageContext }}
            setBuyMoreDialog={setBuyMoreDialog}
            navigateToPage={navigateToPage}
          />
        ))}
    </div>
  );
};

export default AlertsSection;
