import {
  ButtonLink,
  Icon,
  IconComponentType,
  InlineLink,
  Txt,
} from '@vst/beam';
import { House, Wifi } from '@vst/beam-icons/icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import useNavigate from '@mfe/services/navigation';
import { StackLink } from '@mfe/legacy/mv/utils/Navigation';
import { CustomStandardModal, useCanChangePlan } from '@mfe/shared/components';
import { selectDisconnectInternet } from '@mfe/to-be-migrated/redux/disconnect';

import styles from './styles.module.scss';
import { selectUser } from '@mfe/to-be-migrated/redux/auth';
import { ProductInstanceStatus } from '@mfe/shared/schema-types';

export const ManagePlanModal = ({
  isOpen,
  handleCloseModal,
  navigateToChangePlan,
}: {
  isOpen: boolean;
  handleCloseModal: () => void;
  navigateToChangePlan: () => void;
}) => {
  const { t } = useTranslation('NewProfile');
  const { goTo } = useNavigate();
  const canChangePlan = useCanChangePlan();
  const {
    success: hasDisconnected,
    hasCanceledDisconnectOrder,
    hasCIFPlan,
  } = useSelector(selectDisconnectInternet);
  const {
    user: { productInstanceStatus },
  } = useSelector(selectUser);

  const hasCancelledInternetAccountStatus =
    productInstanceStatus === ProductInstanceStatus.Canceled;
  const hasDisconnectedInternet = hasDisconnected || hasCIFPlan;
  const canCancelViasatInternet =
    (!hasDisconnectedInternet || hasCanceledDisconnectOrder) &&
    !hasCancelledInternetAccountStatus;

  const handleChangePlan = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.preventDefault();
    navigateToChangePlan();
  };
  const handleCancelPlan = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    goTo(StackLink.Disconnect);
  };

  return (
    <CustomStandardModal
      size="xLarge"
      isOpen={isOpen}
      header={t('managePlan.header')}
      onClose={handleCloseModal}
      actions={{
        primary: { label: t('managePlan.close'), onClick: handleCloseModal },
      }}
    >
      <div className={styles['modal-content']}>
        <Txt variant="bodyLargeRegular">{t('managePlan.description')}</Txt>
        <div className={styles['action-cards']}>
          {canChangePlan && (
            <PrimaryActionCard
              description={t('managePlan.changeActionDescription')}
              label={t('managePlan.changeActionLabel')}
              icon={Wifi}
              onClick={handleChangePlan}
            />
          )}
          <PrimaryActionCard
            description={t('managePlan.callActionDescription')}
            label={t('managePlan.callActionLabel')}
            icon={House}
            href={`tel:${t('managePlan.carePhoneNumber')}`}
          />
        </div>
        {canCancelViasatInternet && (
          <SecondaryAction
            description={t('managePlan.cancelActionDescription')}
            link={t('managePlan.cancelActionLabel')}
            onClick={handleCancelPlan}
          />
        )}
        <SecondaryAction
          description={t('managePlan.otherActionDescription')}
          href={`tel:${t('managePlan.carePhoneNumber')}`}
          link={t('managePlan.carePhoneNumber')}
        />
      </div>
    </CustomStandardModal>
  );
};

const PrimaryActionCard = ({
  description,
  label,
  icon,
  onClick,
  href = '#',
}: {
  description: string;
  label: string;
  icon: IconComponentType;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  href?: string;
}) => {
  return (
    <div className={styles['action-card']}>
      <Txt variant="bodyLargeRegular">{description}</Txt>
      <div className={styles['primary-action']} onClick={onClick}>
        <ButtonLink href={href} label={label} />
        <Icon icon={icon} color="blue_600" />
      </div>
    </div>
  );
};

const SecondaryAction = ({
  description,
  link,
  href = '#',
  onClick,
}: {
  description: string;
  link: string;
  href?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}) => (
  <div className={styles['secondary-action']}>
    <Txt variant="bodySmallRegular">
      <span>{description}</span>
      <InlineLink href={href} onClick={onClick}>
        {link}
      </InlineLink>
    </Txt>
  </div>
);
