import { ApolloQueryResult, gql } from '@apollo/client';
import { takeLatest, call, put, all, select, take } from 'redux-saga/effects';
import { Action } from '@reduxjs/toolkit';
import {
  Query,
  Locale,
  TokenType,
  InternetProduct,
  ProductInstanceStatus,
} from '@mfe/shared/schema-types';
import {
  selectUser,
  setUser,
  setAuth,
  fetchUserAction,
  setAuthUserError,
  setLocation,
} from '.';
import { sortProductInstancesByStatus } from '../../serverUtils';
import { selectLocale } from '../locale';
import { setAddress } from '../userInfo';
import { getCurrentAddOns } from '../addOns/addOnsSlice';

import {
  graphqlQueryWithErrors,
  FetchWithErrorsQuery,
} from '@mfe/shared/redux/graphql';
import { getWorkOrders } from '../workOrders';
import { getHasPendingTransition, getPlans } from '../changePlan';
import { getStreamEligibility } from '../streamOnPromo';

export const GET_BEP_INPUTS = gql`
  query getBEPInputs($refetchData: Boolean) {
    getBEPInputs(refetchData: $refetchData) {
      partyId
      relnId
      accountNumber
      nationalId
      internetProducts {
        kind
        isACPSuspended
        isSoftSuspended
        hasCaf
        productInstanceId
        productInstanceStatus
        productTypeId
      }
      coordinates {
        latitude
        longitude
      }
      address {
        addressLines
        municipality
        region
        postalCode
        countryCode
      }
      indoorUnit {
        deviceModel
      }
      serviceContractId
    }
  }
`;

export const parseUser = (
  data: Partial<ApolloQueryResult<Query>['data']>,
  planChanged?: boolean
) => {
  const bepInputs = data.getBEPInputs;

  const productSortedByStatus = sortProductInstancesByStatus(
    (bepInputs?.internetProducts as InternetProduct[]) ?? [],
    planChanged
  );

  return {
    accountNumber: bepInputs?.accountNumber ?? '',
    productInstanceId: productSortedByStatus?.[0]?.productInstanceId ?? null,
    productTypeId: productSortedByStatus?.[0]?.productTypeId ?? null,
    productKind: productSortedByStatus?.[0]?.kind ?? null,
    productInstanceStatus:
      productSortedByStatus?.[0]?.productInstanceStatus ??
      ProductInstanceStatus.Other,
    partyId: bepInputs?.partyId ?? '',
    relnId: bepInputs?.relnId ?? '',
    serviceAddress: bepInputs?.address,
    coordinates: bepInputs?.coordinates,
    isACPSuspended: !!bepInputs?.internetProducts?.find(
      (productInstance) => productInstance?.isACPSuspended
    ),
    isSoftSuspended: !!bepInputs?.internetProducts?.find(
      (productInstance) => productInstance?.isSoftSuspended
    ),
    hasCaf: !!bepInputs?.internetProducts?.find(
      (productInstance) => productInstance?.hasCaf
    ),
    hasErrorProductInstance: !!bepInputs?.internetProducts?.find(
      (productInstance) =>
        productInstance?.productInstanceStatus === ProductInstanceStatus.Error
    ),
    indoorUnit: bepInputs?.indoorUnit,
  };
};

interface FetchUserInputType {
  username?: string;
  partyId?: string;
  locale?: Locale;
}

export function* fetchUser() {
  const {
    user: {
      auth: {
        username,
        tokenInfo: { type },
      },
    },
  }: ReturnType<typeof selectUser> = yield select(selectUser);

  const {
    locale: { userLocale },
  }: ReturnType<typeof selectLocale> = yield select(selectLocale);

  const input: FetchUserInputType =
    type === TokenType.Salesforce ? { partyId: username } : { username };
  if (userLocale) input.locale = userLocale;

  const apiResponse: FetchWithErrorsQuery = yield call(graphqlQueryWithErrors, {
    query: GET_BEP_INPUTS,
    variables: { refetchData: true },
    fetchPolicy: 'network-only',
  });
  const { data, errors, runtimeError } = apiResponse;

  if (runtimeError || errors) {
    yield put(setAuthUserError(runtimeError ?? errors));
    return;
  }

  if (data?.getBEPInputs) {
    const {
      accountNumber,
      productInstanceId,
      productTypeId,
      productInstanceStatus,
      productKind,
      partyId,
      relnId,
      serviceAddress,
      coordinates,
      isACPSuspended,
      isSoftSuspended,
      hasCaf,
      hasErrorProductInstance,
      indoorUnit,
    } = parseUser(data);

    yield put(
      setUser({
        accountNumber,
        productInstanceId,
        productTypeId,
        productInstanceStatus,
        productKind,
        partyId,
        relnId,
        isACPSuspended,
        isSoftSuspended,
        hasCaf,
        hasErrorProductInstance,
        indoorUnit,
      })
    );

    yield put(setLocation({ address: serviceAddress, coordinates }));
    yield call(requestDataIfEligible, getPlans());
    yield put(getCurrentAddOns());
    yield put(getStreamEligibility());
    yield put(getHasPendingTransition());
    yield put(setAddress({ service: serviceAddress }));
    yield put(getWorkOrders());
  }
}

function* requestDataIfEligible(action: Action) {
  const {
    user: { productInstanceStatus },
  } = yield select(selectUser);

  if (
    productInstanceStatus === ProductInstanceStatus.Error ||
    productInstanceStatus === ProductInstanceStatus.Suspended ||
    productInstanceStatus === ProductInstanceStatus.Deactivated ||
    productInstanceStatus === ProductInstanceStatus.Canceled ||
    productInstanceStatus === ProductInstanceStatus.Preinstall
  ) {
    return;
  }

  yield put(action);
}

export function* waitForUserData() {
  const { loading } = yield select(selectUser);

  if (!loading) {
    yield take(setUser.type);
  }
}

export function* watchUser() {
  yield all([
    takeLatest(setAuth.type, fetchUser),
    takeLatest(fetchUserAction.type, fetchUser),
  ]);
}
