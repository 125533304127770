import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import {
  FetchWithErrorsMutation,
  FetchWithErrorsQuery,
  graphqlMutationWithErrors,
  graphqlQueryWithErrors,
} from '@mfe/shared/redux/graphql';
import {
  BillingAccount,
  Etf,
  PurchaseRetentionOfferInput,
  RetentionOffer,
} from '@mfe/shared/schema-types';
import {
  ScrubAddressProcessStatus,
  ScrubAddressRecommendation,
} from '@mfe/shared/graphql/PAL/types';
import {
  disconnectViasatInternet,
  getEtf,
  getPurchaseRetentionOffers,
  getRetentionOffers,
  ProcessScrubAction,
  processScrubResult,
  resetDisconnectInternet,
  setCancelInFlightOrder,
  setCancelOrder,
  setDisconnectError,
  setEtf,
  setEtfError,
  setHasCancelledDisconnectOrder,
  setHasCancelledInFlightOrder,
  setPurchaseRetentionOfferError,
  setPurchaseRetentionOfferSuccess,
  setRetentionOffers,
  setRetentionOffersError,
  setScheduledToDisconnectGlobalAlert,
  setViasatInternetDisconnected,
} from './slice';
import { waitForToken } from '../utils/utilsSagas';
import { setShowModal } from '../scrubbedAddress';
import {
  CANCEL_ORDER,
  DELETE_VIASAT_INTERNET,
  GET_ETF,
  GET_RETENTION_OFFERS,
  GET_SCHEDULED_FOR_DELETION_ORDERS,
  PURCHASE_RETENTION_OFFER,
} from './requests';
import { selectLocation } from '@mfe/to-be-migrated/redux/auth';
import { ErrorType } from '@mfe/to-be-migrated/redux/plan';
import { sanitizeAddressLines } from '@mfe/to-be-migrated/redux/disconnect/utils';
import {
  getBillingAccount,
  selectBillingAccount,
} from '@mfe/to-be-migrated/redux/billingInfo';
import { Alert, removeAlert } from '@mfe/to-be-migrated/redux/alerts';
import { parseAccountAlerts } from '@mfe/to-be-migrated/redux/alerts/parseAccountAlerts';
import {
  saveAlertNameInStorage,
  SESSION_STORAGE_VARIABLES,
  Storage,
} from '@mfe/shared/util';

enum ModalTypeEnum {
  Address = 'AddressValidation',
  AddressError = 'AddressError',
  IncorrectAddress = 'IncorrectAddress',
}

function* fetchRetentionOffers() {
  yield call(waitForToken);

  const response: FetchWithErrorsQuery = yield call(graphqlQueryWithErrors, {
    query: GET_RETENTION_OFFERS,
  });

  const { data, errors, runtimeError } = response;

  if (errors || runtimeError) {
    yield put(setRetentionOffersError(runtimeError ?? errors));
    return;
  }

  if (data?.getRetentionOffers) {
    yield put(setRetentionOffers(data?.getRetentionOffers as RetentionOffer[]));
  }
}

function* displayScheduledToDisconnectGlobalAlert() {
  const billingAccount: BillingAccount = yield select(selectBillingAccount);
  const { billDate } = billingAccount;

  if (!billDate) {
    yield put(getBillingAccount());
  }

  try {
    const response: FetchWithErrorsQuery = yield call(graphqlQueryWithErrors, {
      query: GET_SCHEDULED_FOR_DELETION_ORDERS,
    });

    const { data } = response;

    if (data?.getScheduledForDeletionOrders?.orderId && billDate) {
      yield put(setViasatInternetDisconnected());
    }
  } catch (error) {
    console.error(error);
  }
}

function* purchaseRetentionOffer(
  action: PayloadAction<PurchaseRetentionOfferInput>
) {
  try {
    const response: FetchWithErrorsMutation = yield call(
      graphqlMutationWithErrors,
      {
        mutation: PURCHASE_RETENTION_OFFER,
        variables: { input: action.payload },
        fetchPolicy: 'no-cache',
      }
    );
    const { data, errors, runtimeError } = response;

    if (errors || runtimeError) {
      yield put(setPurchaseRetentionOfferError(runtimeError ?? errors));
      return;
    }

    if (data?.purchaseRetentionOffer?.success) {
      yield put(
        setPurchaseRetentionOfferSuccess(data.purchaseRetentionOffer.success)
      );
    }
  } catch (error) {
    yield put(setPurchaseRetentionOfferError(error));
  }
}

function* deleteViasatInternet(action: PayloadAction<string>) {
  const { coordinates, address } = yield select(selectLocation);

  try {
    const response: FetchWithErrorsMutation = yield call(
      graphqlMutationWithErrors,
      {
        mutation: DELETE_VIASAT_INTERNET,
        fetchPolicy: 'no-cache',
        variables: {
          input: {
            ...sanitizeAddressLines(address, coordinates),
            executionDate: action.payload,
          },
        },
      }
    );

    const { data, errors, runtimeError } = response;

    if (errors || runtimeError) {
      yield put(setDisconnectError(runtimeError ?? errors));
      return;
    }

    if (data?.deleteViasatInternet?.status?.toLowerCase() === 'success') {
      yield put(setViasatInternetDisconnected());
    }
  } catch (error) {
    yield put(setDisconnectError(error as ErrorType));
  }
}

function* cancelInFlightOrder() {
  try {
    const response: FetchWithErrorsMutation = yield call(
      graphqlMutationWithErrors,
      {
        mutation: CANCEL_ORDER,
        fetchPolicy: 'no-cache',
        variables: {
          input: { reason: 'Retention', cif: true },
        },
      }
    );

    const { data, errors, runtimeError } = response;

    if (errors || runtimeError) {
      yield put(setDisconnectError(runtimeError ?? errors));
      return;
    }

    if (data?.cancelOrder) {
      const alerts: Alert[] = yield call(parseAccountAlerts);
      for (const alert of alerts) {
        saveAlertNameInStorage(alert.title);
        yield put(removeAlert(alert.title));
      }
      yield put(setHasCancelledInFlightOrder(true));

      Storage.setItem(SESSION_STORAGE_VARIABLES.CIF_ORDER_SUCCESS, true);
      yield put(setViasatInternetDisconnected());
    }
  } catch (error) {
    yield put(setDisconnectError(error as ErrorType));
  }
}

function* cancelOrder() {
  try {
    const response: FetchWithErrorsMutation = yield call(
      graphqlMutationWithErrors,
      {
        mutation: CANCEL_ORDER,
        fetchPolicy: 'no-cache',
        variables: {
          input: { reason: 'Retention' },
        },
      }
    );

    const { data, errors, runtimeError } = response;

    if (errors || runtimeError) {
      yield put(setViasatInternetDisconnected());
    }

    if (data?.cancelOrder) {
      yield put(resetDisconnectInternet());
      yield put(setHasCancelledDisconnectOrder(true));
    }
  } catch (error) {
    yield put(setViasatInternetDisconnected());
  }
}

function* fetchEarlyTerminationFees() {
  yield call(waitForToken);

  const response: FetchWithErrorsQuery = yield call(graphqlQueryWithErrors, {
    query: GET_ETF,
  });

  const { data, errors, runtimeError } = response;

  if (errors || runtimeError) {
    yield put(setEtfError(runtimeError ?? errors));
    return;
  }

  if (data?.getEarlyTerminationFees) {
    yield put(setEtf(data.getEarlyTerminationFees as Etf[]));
  }
}

function* processScrubedAddress(action: ProcessScrubAction) {
  const { processStatus, recommendation } = action.payload.scrubbedAddress;

  // Copy-paste from addOnsSagas
  // To do: use the same implementation for both disconnect & voice
  if (
    processStatus === ScrubAddressProcessStatus.Verified ||
    (processStatus === ScrubAddressProcessStatus.Corrected &&
      recommendation === ScrubAddressRecommendation.RECOMMEND)
  ) {
    yield put(setShowModal(null));
    return;
  }

  if (
    processStatus === ScrubAddressProcessStatus.Incorrect &&
    recommendation === ScrubAddressRecommendation.REJECT
  ) {
    yield put(setShowModal(ModalTypeEnum.IncorrectAddress));
    return;
  }

  if (processStatus === ScrubAddressProcessStatus.Corrected) {
    return;
  }
  yield put(setShowModal(ModalTypeEnum.AddressError));
}

export function* watchDisconnect() {
  yield takeLatest(getEtf.type, fetchEarlyTerminationFees);
  yield takeLatest(processScrubResult.type, processScrubedAddress);
  yield takeLatest(getRetentionOffers.type, fetchRetentionOffers);
  yield takeLatest(getPurchaseRetentionOffers.type, purchaseRetentionOffer);
  yield takeLatest(disconnectViasatInternet.type, deleteViasatInternet);
  yield takeLatest(setCancelOrder.type, cancelOrder);
  yield takeLatest(
    setScheduledToDisconnectGlobalAlert.type,
    displayScheduledToDisconnectGlobalAlert
  );
  yield takeLatest(setCancelInFlightOrder.type, cancelInFlightOrder);
}
