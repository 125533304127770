import { Txt } from '@vst/beam';
import { format } from 'date-fns';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  getCharacteristics,
  selectPlanCharacteristics,
} from '@mfe/to-be-migrated/redux/plan';
import {
  getCurrentAddOns,
  selectAddOns,
} from '@mfe/to-be-migrated/redux/addOns';
import { AddonIcon } from '@mfe/shared/components';
import { ProductInstanceTypes } from '@mfe/shared/graphql/PSM/types';
import { getEtf, selectEtf } from '@mfe/to-be-migrated/redux/disconnect';
import {
  getBillingAccount,
  selectBillingAccount,
} from '@mfe/to-be-migrated/redux/billingInfo';

import { renderMultilineLoading } from './renderMultilineLoading';
import styles from './styles.module.scss';
import { LoadingCard } from './loading-card';
import { getNextBillingLifecycle } from './utils';
import { selectHasPreinstallProductInstanceStatus } from '@mfe/to-be-migrated/redux/auth';

export const DisconnectionCart = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('Disconnect');

  const billingAccount = useSelector(selectBillingAccount);
  const { currentAddOns, loading: addonsLoading } = useSelector(selectAddOns);
  const { characteristics, loading: planLoading } = useSelector(
    selectPlanCharacteristics
  );
  const hasPreinstall = useSelector(selectHasPreinstallProductInstanceStatus);

  useEffect(() => {
    dispatch(getEtf());

    if (!characteristics.name) dispatch(getCharacteristics());
    if (!currentAddOns) dispatch(getCurrentAddOns());
    if (!billingAccount.nextBillPeriodStartDate) dispatch(getBillingAccount());
  }, []);

  const nextBillingCycleDate = getNextBillingLifecycle(billingAccount);

  return (
    <div>
      <Txt variant="heading6" mb="16px">
        {t('cart.subscriptions.heading')}
      </Txt>
      <Txt variant="bodySmallRegular" mb="24px">
        {t('cart.subscriptions.description')}
      </Txt>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
        {planLoading ? (
          <LoadingCard />
        ) : (
          <SubscriptionCard
            name={characteristics.name}
            icon={
              <AddonIcon
                kind={ProductInstanceTypes.Internet}
                className={styles['icon']}
              />
            }
          />
        )}
        {addonsLoading ? (
          <LoadingCard />
        ) : (
          currentAddOns?.map(({ name, kind }) => {
            return (
              <SubscriptionCard
                key={name}
                name={name}
                icon={<AddonIcon kind={kind} className={styles['icon']} />}
              />
            );
          })
        )}
        {!hasPreinstall && (
          <>
            <hr className={styles['separator']} />
            <Txt variant="heading6">{t('cart.disconnectDate.heading')}</Txt>
            {billingAccount.loading ? (
              renderMultilineLoading(2)
            ) : (
              <Txt variant="bodySmallRegular">
                {nextBillingCycleDate
                  ? t('cart.disconnectDate.description', {
                      nextBillingCycleDate,
                    })
                  : t('cart.disconnectDate.descriptionError')}
              </Txt>
            )}
            <hr className={styles['separator']} />
          </>
        )}
      </div>
      {!hasPreinstall && <ETF />}
    </div>
  );
};

type SubscriptionCardProps = {
  name: string;
  icon: React.ReactNode;
};

const SubscriptionCard = ({ name, icon }: SubscriptionCardProps) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: 24 }}>
      {icon}
      <Txt variant="bodySmallBold">{name}</Txt>
    </div>
  );
};

const ETF = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('Disconnect');

  const { data, loading, error } = useSelector(selectEtf);
  const remainingMonths = data?.remainingMonthsInContract;
  const totalMonths = data?.totalMonthsInContract;
  const totalEtf = data?.calculatedEtf?.value ?? '';

  const billingAccount = useSelector(selectBillingAccount);

  useEffect(() => {
    if (!billingAccount.dueDate) dispatch(getBillingAccount());
  }, []);

  const dueDate = billingAccount?.dueDate
    ? format(billingAccount?.dueDate, 'MMM dd')
    : '';

  return (
    <div>
      <Txt variant="heading6" mb="16px" mt="24px">
        {t('cart.etf.heading')}
      </Txt>
      {loading || billingAccount.loading ? (
        renderMultilineLoading(5)
      ) : (
        <>
          <Txt variant="tinyRegular" color="subtle">
            {error
              ? t('cart.etf.descriptionError')
              : t('cart.etf.description', {
                  remainingMonths,
                  totalMonths,
                  dueDate,
                })}
          </Txt>
          {totalEtf !== null || totalEtf !== undefined ? (
            <>
              <div className={styles['etfFee']}>
                <Txt variant="bodySmallRegular" color="subtle">
                  {t('cart.etfFee')}
                </Txt>
                <Txt variant="smallBold" color="subtle">
                  ${totalEtf}
                </Txt>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Txt variant="paragraphBold" mt="16px">
                  ${totalEtf}
                </Txt>
              </div>
            </>
          ) : null}
        </>
      )}
    </div>
  );
};
