export const BOLETO = 'Boleto';

export const SESSION_STORAGE_VARIABLES = {
  PURCHASED_PRODUCT_TYPE_ID: 'purchasedProductTypeId',
  DISMISSED_ALERTS: 'dismissedAlerts',
  CHANGE_PLAN_SUCCESS: 'changePlanSuccess',
  HIDE_ACP_BANNER: 'hideAcpBanner',
  ACP_REGISTERED: 'acpRegistered',
  SHOW_PLAN_TAB: 'showPlanTab',
  DIAGNOSTIC_RUNS_TIMESTAMPS: 'diagnosticRunsTimestamps',
  CIF_ORDER_SUCCESS: 'cancelInFlightOrderSuccess',
};

export const VOICE_ONLY_CONNECTION_PRODUCT_TYPE_ID =
  '147e55f6-8f33-42b0-b868-6750cee05047';

export const PAYMENT_METHOD_NAME = {
  ACH: 'ACH',
  AmericanExpress: 'American Express',
  Boleto: 'Boleto',
  DinersClub: 'Diners Club',
  Discover: 'Discover',
  Elo: 'Elo',
  Hipercard: 'Hipercard',
  MasterCard: 'MasterCard',
  PayPal: 'PayPal',
  Pix: 'Pix',
  SEPA: 'SEPA',
  Visa: 'Visa',
} as const;

export const AUTOPAY_PREAUTH_AMOUNT = 2.01;

export const VOICE_ADDONS_KINDS = [
  'RESIDENTIAL_VOICE',
  'VOICE_ATA',
  'BUSINESS_VOICE',
  'BUSINESS_VOICE_L3',
];

export const ADDON_KINDS = [
  ...VOICE_ADDONS_KINDS,
  'EASY_CARE',
  'SHIELD',
  'STATIC_IP',
  'OFFICE_HOURS',
  'CONTENT_ADD_ON',
];

export enum ADDON_KIND {
  RESIDENTIAL_VOICE = 'RESIDENTIAL_VOICE',
  VOICE_ATA = 'VOICE_ATA',
  BUSINESS_VOICE = 'BUSINESS_VOICE',
  BUSINESS_VOICE_L3 = 'BUSINESS_VOICE_L3',
  EASY_CARE = 'EASY_CARE',
  SHIELD = 'SHIELD',
  STATIC_IP = 'STATIC_IP',
  OFFICE_HOURS = 'OFFICE_HOURS',
  CONTENT_ADD_ON = 'CONTENT_ADD_ON',
}

export const EASY_CARE_ADDON_KINDS = ['EASY_CARE'];
export const SHIELD_ADDON_KINDS = ['SHIELD'];
export const STATIC_IP_ADDON_KINDS = ['STATIC_IP'];
export const OFFICE_HOURS_ADDON_KINDS = ['OFFICE_HOURS'];

export const ADDON_PRODUCT_KIND = {
  easyCare: 'bep.ofm.product-types.easy-care',
  shield: 'bep.ofm.package-types.shield',
  staticIp: 'bep.ofm.package-types.static-ip',
  voice: 'bep.ofm.product-types.satellite-internet-add-on',
  officeHours: 'bep.ofm.product-types.office-hours',
  viasatStream: 'bep.ofm.product-types.streamon',
} as const;

export enum ADD_ON_STATE {
  INCLUDED = 'Included',
  SUBSCRIBED = 'Subscribed',
  PENDING = 'Pending deactivation',
}

export enum MarketingCharacteristics {
  OFFER_NAME = 'OFFER_NAME',
  OFFER_DESCRIPTION = 'OFFER_DESCRIPTION',
  UI_TYPE = 'UI_TYPE',
  DISCLOSURE = 'DISCLOSURE',
}
