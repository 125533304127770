import { useTranslation } from 'react-i18next';
import { Button, InlineLink, SectionAlert, Surface, Txt } from '@vst/beam';

import { useScreenResolution } from '@mfe/shared/util';
import useNavigate from '@mfe/services/navigation';
import { Storage, SESSION_STORAGE_VARIABLES } from '@mfe/shared/util';

import { PageLayout } from '../shared/page-layout';
import { Survey } from './survey';
import { ContactInformation } from './contact-information';
import { Footer } from '../shared/footer';
import { DisconnectionCart } from '../shared/disconnection-cart';

import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { selectDisconnectInternet } from '@mfe/to-be-migrated/redux/disconnect';
import React from 'react';
import { selectHasPreinstallProductInstanceStatus } from '@mfe/to-be-migrated/redux/auth';

export const DisconnectStep1 = ({
  handleContinue,
  formError,
}: {
  handleContinue: () => void;
  formError?: string;
}) => {
  const { goBack } = useNavigate();
  const { t } = useTranslation('Disconnect');
  const { replace } = useNavigate();

  const { isSmall, isExtraSmall } = useScreenResolution();
  const isMobile = isSmall || isExtraSmall;

  const { success: hasCancelledPlan } = useSelector(selectDisconnectInternet);

  const hasPreinstall = useSelector(selectHasPreinstallProductInstanceStatus);

  React.useEffect(() => {
    if (hasCancelledPlan) {
      Storage.setItem(SESSION_STORAGE_VARIABLES.SHOW_PLAN_TAB, true);
      replace('Profile');
    }
  }, [hasCancelledPlan]);

  const handleCancel = () => {
    Storage.setItem(SESSION_STORAGE_VARIABLES.SHOW_PLAN_TAB, true);
    goBack();
  };

  return (
    <PageLayout
      step={1}
      header={
        <div className={styles['title']}>
          <Txt variant={isMobile ? 'heading5' : 'heading3'}>
            {t('page.title.intro')}
          </Txt>
        </div>
      }
      leftContent={
        <LeftSection
          handleCancel={handleCancel}
          handleContinue={handleContinue}
          hasPreinstall={hasPreinstall}
          formError={formError}
        />
      }
      rightContent={<DisconnectionCart />}
    />
  );
};

const LeftSection = ({
  handleCancel,
  handleContinue,
  hasPreinstall,
  formError,
}: {
  handleCancel: () => void;
  handleContinue: () => void;
  hasPreinstall: boolean;
  formError?: string;
}) => {
  const { t } = useTranslation(['Disconnect', 'Global']);
  return (
    <>
      <Surface className={styles['leftCardContent']}>
        <SectionAlert
          showIcon={false}
          variant="infoSecondary"
          className={styles['sectionAlert']}
        >
          <div>
            <Txt component="span" variant="bodySmallBold">
              {t('survey.alert.question')}
            </Txt>
            <Txt component="span" variant="bodySmallRegular">
              {t('survey.alert.callUsAt')}
            </Txt>
            <InlineLink
              variant="primary"
              className={styles['customerSupportTelLink']}
              href={`tel:${t('Global:callCustomerSupportPhoneNumber')}`}
              style={{ fontSize: '16px' }}
            >
              {t('Global:callCustomerSupportPhoneNumberDisplay')}
            </InlineLink>
            <Txt component="span" variant="bodySmallRegular">
              {t('survey.alert.toGetStarted')}
            </Txt>
          </div>
        </SectionAlert>
        <div>
          <Txt mb="8px" variant="bodyLargeBold">
            {t('survey.heading')}
          </Txt>
          <Txt variant="bodySmallRegular">{t('survey.description')}</Txt>
        </div>
        <hr className={styles['separator']} />
        <Survey hasPreinstall={hasPreinstall} formError={formError} />
        {hasPreinstall ? null : <ContactInformation />}
      </Surface>
      <Footer>
        <Button variant="tertiary" onClick={handleCancel}>
          {t('button.cancel')}
        </Button>
        <Button variant="primary" onClick={handleContinue}>
          {t('button.continue')}
        </Button>
      </Footer>
    </>
  );
};
