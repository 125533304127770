import React, { useEffect, useRef } from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Surface, Txt } from '@vst/beam';

import {
  AlertAction,
  AlertScheduleInstallation,
  DatePicker,
  HeaderWithBackNavigation,
  OnSiteContactInformation,
  ScheduleInstallationForm as Form,
  SelectedDate,
  TimePicker,
  useScheduleInstallation,
} from '@mfe/shared/components';
import { useScreenResolution } from '@mfe/shared/util';
import {
  refetchScheduleInstallation,
  selectScheduleInstallation,
  submitInstallation,
} from '@mfe/to-be-migrated/redux/scheduleInstallation';
import {
  fetchAccountAlerts,
  resetAlerts,
} from '@mfe/to-be-migrated/redux/alerts';
import { ScheduleInput } from '@mfe/shared/schema-types';
import { getCharacteristics, getUsage } from '@mfe/to-be-migrated/redux/plan';
import useNavigate from '@mfe/services/navigation';

import { CalendarLoading } from './calendar-loading';
import { ScheduleLoading } from './schedule-loading';
import { ScheduleSuccess } from './schedule-success';
import { ScheduleFailure } from './schedule-failure';
import { AvailableInstallationDatesFailure } from './available-installation-dates-failure';
import { NoAppointments } from './no-appointments';
import CurrentSchedule from './current-schedule';

import styles from './index.module.scss';

const PAL_DID_NOT_RETURN_DATA = 'PAL did not return data';

export const ScheduleInstallationForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('NewScheduleInstallation');

  const refreshPage = () => {
    dispatch(refetchScheduleInstallation());
  };

  const {
    textInput,
    setTextInput,
    textAreaInput,
    setTextAreaInput,
    textAreaMaxLength,
    calendarLocale,
    selectedDate,
    selectedInterval,
    setSelectedInterval,
    handleDateSelection,
    showAlert,
    setShowAlert,
    phoneNumberInput,
    setPhoneNumberInput,
    isPhoneNumberValid,
    isNameValid,
    textFieldRef,
    phoneFieldRef,
  } = useScheduleInstallation();

  const validationMessageType = React.useMemo(
    () => computeValidationMessage(selectedDate, selectedInterval),
    [selectedDate, selectedInterval]
  );

  const {
    installDate,
    loading,
    error,
    availableDates,
    externalWorkOrderId,
    submitLoading,
    submitSuccess,
  } = useSelector(selectScheduleInstallation);

  const { isExtraSmall, isSmall } = useScreenResolution();
  const isMobile = isExtraSmall || isSmall;
  const { goBack: navigateToPreviousPage } = useNavigate();

  const alertRef = useRef<HTMLDivElement>(null);

  React.useEffect(() => setShowAlert(false), [selectedDate, setShowAlert]);

  const handleBackNavigation = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    navigateToPreviousPage();
  };

  const handleOverviewNavigation = () => {
    dispatch(resetAlerts());
    // Fetch usage & characteristics again for alerts
    dispatch(getUsage());
    dispatch(getCharacteristics());
    dispatch(fetchAccountAlerts());
    navigateToPreviousPage();
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setShowAlert(true);

    if (validationMessageType !== AlertAction.DateAndTimeConfirmed) {
      return;
    }

    const content = `${textInput} ${phoneNumberInput} ${textAreaInput}` ?? '';
    const input = {
      externalWorkOrderId,
      note: { content },
      schedule: { ...selectedInterval },
    };

    dispatch(submitInstallation(input));
  };

  const availableDays = React.useMemo(() => {
    if (!loading && availableDates) {
      return Object.keys(availableDates).map((date: string) => {
        const currentDate = new Date(date);
        const processedCurrentDate = new Date(
          currentDate.valueOf() + currentDate.getTimezoneOffset() * 60 * 1000
        );
        return processedCurrentDate;
      });
    }
    return [];
  }, [availableDates, loading]);

  const formattedSelectedDate = format(
    new Date(
      selectedDate.year as number,
      (selectedDate.month as number) - 1,
      selectedDate.day as number
    ),
    'yyyy-MM-dd'
  );

  const intervals = React.useMemo(() => {
    if (!loading && availableDates) {
      return availableDates[formattedSelectedDate];
    }
    return [];
  }, [loading, availableDates, formattedSelectedDate]);

  const headerMargin = isMobile
    ? {
        margin: '8px auto',
      }
    : {};

  const scrollToElement = () => {
    if (alertRef.current) {
      alertRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  useEffect(() => {
    scrollToElement();
  }, [showAlert]);

  if (error) {
    return error === PAL_DID_NOT_RETURN_DATA ? (
      <AvailableInstallationDatesFailure
        refreshPage={refreshPage}
        goToOverview={handleOverviewNavigation}
      />
    ) : (
      <ScheduleFailure goToOverview={handleOverviewNavigation} />
    );
  }

  if (submitLoading) {
    return <ScheduleLoading />;
  }

  if (submitSuccess) {
    return (
      <ScheduleSuccess
        selectedDate={selectedDate}
        selectedInterval={selectedInterval}
        goToOverview={handleOverviewNavigation}
      />
    );
  }

  const noAvailableDates =
    availableDates && Object.keys(availableDates).length === 0;

  if (noAvailableDates) {
    return <NoAppointments navigateToPreviousPage={navigateToPreviousPage} />;
  }

  return (
    <>
      <HeaderWithBackNavigation
        title={installDate ? t('altTitle') : t('mainTitle')}
        handleBackNavigation={handleBackNavigation}
        style={headerMargin}
        goBackLabel={t('goBack')}
      />
      <Surface className={styles['content-wrapper']} radius="16px">
        <CurrentSchedule />

        <Form
          initialValues={{
            'appointment-contact': textInput,
            phone: phoneNumberInput,
          }}
          handleSubmit={handleSubmit}
        >
          <Txt className={styles['calendar-title']} variant="bodyLargeBold">
            {t('calendar.title')}
          </Txt>

          {loading ? (
            <CalendarLoading />
          ) : (
            <Surface className={styles['form-content']}>
              <DatePicker
                locale={calendarLocale}
                selectedDate={selectedDate}
                handleDateSelection={handleDateSelection}
                availableDays={availableDays}
              />

              {selectedDate.day && (
                <Surface mt="40px">
                  <TimePicker
                    intervals={intervals}
                    selectedInterval={selectedInterval}
                    setSelectedInterval={setSelectedInterval}
                    onTimeIntervalClick={() => setShowAlert(true)}
                  />
                </Surface>
              )}

              {showAlert && (
                <Surface mt="16px" ref={alertRef}>
                  <AlertScheduleInstallation
                    action={validationMessageType}
                    selectedDate={selectedDate}
                    selectedInterval={selectedInterval}
                  />
                </Surface>
              )}

              <OnSiteContactInformation
                setTextInput={setTextInput}
                setTextAreaInput={setTextAreaInput}
                textAreaMaxLength={textAreaMaxLength}
                phoneNumberInput={phoneNumberInput}
                setPhoneNumberInput={setPhoneNumberInput}
                isPhoneNumberValid={isPhoneNumberValid}
                isNameValid={isNameValid}
                textFieldProps={{
                  ref: textFieldRef,
                  name: 'appointment-contact',
                }}
                phoneFieldProps={{
                  ref: phoneFieldRef,
                  name: 'phone',
                }}
              />
            </Surface>
          )}

          <div className={styles['form-button']}>
            <Button
              disabled={loading}
              data-cy="schedule-installation-button"
              type="submit"
              variant="primary"
              fluid={isMobile}
              onClick={() => setShowAlert(true)}
            >
              {t('contactInformation.appointmentButton')}
            </Button>
          </div>
        </Form>
      </Surface>
    </>
  );
};

function computeValidationMessage(
  selectedDate: SelectedDate,
  selectedInterval: ScheduleInput | null
) {
  if (!selectedDate.day) {
    return AlertAction.DateAndTimeRequired;
  }

  if (!selectedInterval?.from) {
    return AlertAction.TimeRequired;
  }

  return AlertAction.DateAndTimeConfirmed;
}
